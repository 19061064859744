<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Bando Personale ATA 2024</li>
            </ul>
            <h2>Bando Personale ATA 2024</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/personale-ata-2024.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <img src="assets/img/miur.png" class="imgMiur">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Ottieni il punteggio utile per scalare le graduatorie ATA così da avere più possibilità nelle graduatorie">
                            <div class="courses-overview">
                               <h3>Che cos’è il Bando per Personale ATA 2024?</h3>
                               <p class="text-dark">
                                    Come sappiamo il <b>bando per personale ATA</b> viene indetto <u><b>ogni tre anni</b></u>. L’ultimo è stato pubblicato in questo 2021 e, quindi, sarà valido fino al 2023. Nel 2024 verrà indetto il nuovo bando con l’assunzione di nuovo personale.
                                </p>
                                <p class="text-dark">
                                    Il prossimo bando per <b>personale ATA 2024</b> è quello che permetterà l’assunzione delle nuove figure professionali all’interno delle istituzioni scolastiche statali.
                                    Nello specifico il bando permette <b>l’inserimento in graduatoria di nuovi aspiranti ATA e l’aggiustamento delle graduatorie</b> in base ai nuovi punteggi conseguiti dagli iscritti.
                               </p>

                               <h3>Come prepararsi al Bando ATA 2024</h3>
                               <p class="text-dark">
                                    Per <b>prepararsi al bando ATA 2024</b> il consiglio è quello di iniziare a seguire dei <b>corsi in grado di dare punteggio per la graduatoria</b>. Questi ultimi non solo permetteranno di accrescere le proprie competenze, ma daranno anche dei punti per scalare le graduatorie.
                                </p>
                                <p class="text-dark">
                                    Un ottimo motivo per iniziare sin da subito a pensare al prossimo bando, così da avere tutto il tempo a propria disposizione e non farsi trovare impreparati.
                                </p>
                                <p class="text-dark">
                                    Ecco, quindi, che vogliamo dare un consiglio specifico a tutti coloro i quali sono alla ricerca dei <b>migliori corsi per aumentare il punteggio del personale ATA</b>.
                                </p>
                                <p class="text-dark">
                                    Sappiamo che questo passaggio è fondamentale per chi desidera avere delle chance nel mondo della scuola ed è per questo motivo che siamo qui per consigliare solo dei corsi in grado di far apprendere nuove skill e aumentare il punteggio.
                                </p>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="courses-details-desc">
                        <ngx-tabset>
                            <ngx-tab tabTitle="I nostri corsi consigliati per aumentare il punteggio del personale ATA e scalare la graduatoria">
                                <div class="courses-overview">
                                    <h3 class="text-uppercase">Corsi per <u>Assistente Amministrativo</u> <span class="fw-normal ms-1">(max 1 certificazione EIPASS)</span></h3>
                                    <p class="text-dark">
                                        con i nostri corsi e certificazioni puoi ottenere un penteggio che varia da un minimo di <b>1.60</b> fino ad un massimo di <b>3.10 punti</b>
                                    </p>
                                    <div class="row">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/operatore-amministrativo-contabile" class="d-block image">
                                                    <img src="{{urlImageHD}}1671624129942.jpg" style="height: 200px; width: 100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€599</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/operatore-amministrativo-contabile">Operatore Amministrativo Contabile</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 300h </li>
                                                    <li class="fw-bold"> riconosciuti +1.50 punti </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/dattilografia" class="d-block image">
                                                    <img src="{{urlImageHD}}dattilografia.jpg" style="height: 200px; width: 100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€79</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/dattilografia">Corso e Certificazione di Dattilografia</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 200h </li>
                                                    <li class="fw-bold"> riconosciuto +1.00 punto </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/certificazione-eipass-7-moduli-user" class="d-block image">
                                                    <img src="{{urlImageHD}}1675527900760.jpg" style="height: 200px; width:100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€119</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/certificazione-eipass-7-moduli-user">Certificazione EIPASS 7 Moduli User</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 200h </li>
                                                    <li class="fw-bold"> da 0.3 a 0.6 punti </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/certificazione-eipass-progressive" class="d-block image">
                                                    <img src="{{urlImageHD}}1675531054402.jpg" style="height: 200px; width: 100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€119</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/certificazione-eipass-progressive">Certificazione EIPASS Progressive</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 100h </li>
                                                    <li class="fw-bold"> da 0.3 a 0.6 punti </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/certificazione-eipass-lim" class="d-block image">
                                                    <img src="{{urlImageHD}}1675531945427.jpg" style="height: 200px; width: 100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€119</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/certificazione-eipass-lim">Certificazione EIPASS LIM</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 200h </li>
                                                    <li class="fw-bold"> da 0.3 a 0.6 punti </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div class="courses-overview mt-5">
                                    <h3 class="text-uppercase">Corsi per <u>Collaboratore Scolastico</u> <span class="fw-normal ms-1">(max 1 certificazione EIPASS)</span></h3>
                                    <p class="text-dark">
                                        con i nostri corsi e certificazioni puoi ottenere un penteggio che varia da un minimo di <b>1.00</b> fino ad un massimo di <b>1.30 punti</b>
                                    </p>
                                    <div class="row">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/osa" class="d-block image">
                                                    <img src="{{urlImageHD}}osa.jpg" style="height: 200px; width: 100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€599</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/osa">Corso operatore socio assistenziale (OSA)</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 300h </li>
                                                    <li class="fw-bold"> riconosciuto +1.00 punto </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/certificazione-eipass-7-moduli-user" class="d-block image">
                                                    <img src="{{urlImageHD}}1675527900760.jpg" style="height: 200px; width:100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€119</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/certificazione-eipass-7-moduli-user">Certificazione EIPASS 7 Moduli User</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 200h </li>
                                                    <li class="fw-bold"> da 0.3 a 0.6 punti </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/certificazione-eipass-progressive" class="d-block image">
                                                    <img src="{{urlImageHD}}1675531054402.jpg" style="height: 200px; width: 100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€119</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/certificazione-eipass-progressive">Certificazione EIPASS Progressive</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 100h </li>
                                                    <li class="fw-bold"> da 0.3 a 0.6 punti </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <img src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                                                <a routerLink="/dettaglio-corso/certificazione-eipass-lim" class="d-block image">
                                                    <img src="{{urlImageHD}}1675531945427.jpg" style="height: 200px; width: 100%; object-fit: cover;" alt="image">
                                                </a>
                                                <div *ngIf="idCorsista==''" class="price shadow">€119</div>
                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/dettaglio-corso/certificazione-eipass-lim">Certificazione EIPASS LIM</a></h3>
                                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                                    <li class="fw-bold"><i class="flaticon-time-left"></i> 200h </li>
                                                    <li class="fw-bold"> da 0.3 a 0.6 punti </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    </div>
                                </div>
                            </ngx-tab>
                        </ngx-tabset>
                    </div>


            </div>
            <div class="col-lg-4 col-md-12 d-none">
                <div class="courses-details-info">
                    <div class="image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                        <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="link-btn popup-youtube"></a>
                        <div class="content">
                            <i class="flaticon-play"></i>
                            <span>Course Preview</span>
                        </div>
                    </div>
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Price</span>
                                $49
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Sarah Taylor
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duration</span>
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lessons</span>
                                25
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Access</span>
                                Lifetime
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Richiedi informazioni<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
